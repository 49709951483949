import Grid from '@mui/material/Grid';
import dynamic from 'next/dynamic';
import { memo, FunctionComponent } from 'react';

import useBreakpoints from '../../Hooks/useBreakpoints';

const SideImage = dynamic(() => import('./SideImage'));
const Text = dynamic(() => import('./Text'));

const Welcome: FunctionComponent = () => {
  const { isDesktop } = useBreakpoints();
  return isDesktop ? (
    <Grid
      container
      item
      justifyContent={{ xs: 'center', lg: 'flex-start' }}
      alignItems="center"
      position="relative"
      sx={{
        flexDirection: { xs: 'column', md: 'column', lg: 'row' },
      }}
    >
      <Text />
      <Grid
        item
        width={{ xs: '100%' }}
        position={{ xs: 'unset', lg: 'absolute' }}
        top={{ xs: 412, md: 0 }}
        right={{ xs: 120, md: 0 }}
        left={{ xs: 0, md: 450 }}
        height={{ xs: '100%', md: 650 }}
        justifyContent={{ xs: 'center', md: 'center', lg: 'flex-start' }}
      >
        <SideImage />
      </Grid>
    </Grid>
  ) : (
    <>
      <Text />
      <SideImage />
    </>
  );
};
export default memo(Welcome);
